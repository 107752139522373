// import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
// import { NgxAuthFirebaseuiService, NgxAuthFirebaseuiComponent } from 'ngx-auth-firebaseui';

import { Component, Inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import firebase from 'firebase/compat/app';

@Component({
  selector: 'app-dialog-auth',
  templateUrl: './dialog-auth.component.html',
  styleUrls: ['./dialog-auth.component.scss']
})
export class DialogAuthComponent {
  loginForm: FormGroup;
  registerForm: FormGroup;
  emailFormControl: FormControl;
  createAccount = false;
  changePassword = false;
  emailVerificationRequired = false;
  changePasswordMessage = ''; 
  newVerificationEmailMessage = ''; 

  constructor(
    private dialogRef: MatDialogRef<DialogAuthComponent>,
    private fb: FormBuilder,
    private afAuth: AngularFireAuth,
    private router: Router,
    // @Inject(MAT_DIALOG_DATA) public data: any // Injecting MAT_DIALOG_DATA
  ) {
    this.loginForm = this.fb.group({
      email: ['', [Validators.required, Validators.email]],
      password: ['', [Validators.required]]
    });

    this.registerForm = this.fb.group({
      email: ['', [Validators.required, Validators.email]],
      password: ['', [Validators.required]],
      name: ['', [Validators.required]]
    });

    this.emailFormControl = new FormControl('', [Validators.required, Validators.email]);
  }

  signIn() {
    const { email, password } = this.loginForm.value;
    this.afAuth.signInWithEmailAndPassword(email, password)
      .then(() => {
        this.dialogRef.close();
        this.verifiedCheck();
      })
      .catch(error => console.error('Error signing in', error));
  }

  signInWithGoogle() {
    this.afAuth.signInWithPopup(new firebase.auth.GoogleAuthProvider())
      .then(() => {
        this.dialogRef.close();
        this.verifiedCheck();
      })
      .catch(error => console.error('Error signing in with Google', error));
  }

  register() {
    const { email, password, name } = this.registerForm.value;
    this.afAuth.createUserWithEmailAndPassword(email, password)
      .then(() => {
        this.dialogRef.close();
        this.verifiedCheck();
      })
      .catch(error => console.error('Error registering', error));
  }

  verifiedCheck() {
    // Implement your email verification check logic here
  }

  displaychangePassword() {
    this.changePassword = !this.changePassword;
  }

  flipAuth() {
    this.createAccount = !this.createAccount;
  }

  executeChangePassword() {
    const email = this.emailFormControl.value;
    this.afAuth.sendPasswordResetEmail(email)
      .then(() => {
        this.changePasswordMessage = 'Password reset email sent!';
      })
      .catch(error => console.error('Error sending password reset email', error));
  }

  resendEmailVerification() {
    // Implement your resend email verification logic here
  }

  signOut() {
    this.afAuth.signOut().then(() => this.router.navigate(['/']));
  }
}